<template>
  <div v-if="startTimer">
    <v-row class="justify-center mt-3">
      <v-col class="bg-date darken-3 grey--text text--lighten-5 container-date-time text-center" cols="8">
        <p class="date text-h5 font-weight-bold"> {{ moveName }} </p>
        <p class="date text-h6 text-capitalize"> {{ simulatedDate | simulateDate }} </p>
        <p class="date text-h3 text-capitalize"> {{ simulatedDate | simulateTime }} </p>
      </v-col>
    </v-row>
    <v-row class="justify-center min-height-3em">
      <v-col cols="12" v-if="status.moveStatus !== 'REALTIME'">
        <v-progress-linear
            :value="progressInPercent"
            background-color="indigo lighten-1"
            color="indigo lighten-2"
            height="25"
            striped
        >
          <strong class="white--text"> {{ progressInPercent }}% </strong>
        </v-progress-linear>
      </v-col>
    </v-row>
  </div>

</template>
<script>
import axios from 'axios';
import Vue from "vue";
import {DateTime} from "luxon";

Vue.filter("simulateDate", (time) => DateTime.fromMillis(time).toLocaleString(DateTime.DATE_HUGE));
Vue.filter("simulateTime", (time) => DateTime.fromMillis(time).toLocaleString(DateTime.TIME_SIMPLE));

const urlApi = "/api/v1/";
export default {
  name: 'SimulatedDate',
  data() {
    return {
      now: 0,
      offset: 0,
      startTimer: 0
    }
  },
  props: {
    actualMove: {},
    status: {},
  },
  created() {
    this.initOffset();
  },
  mounted() {
    this.countdown();
  },
  watch: {
    status: function (newStatus) {
      console.log("newStatus", JSON.stringify(newStatus))
      if (this.startTimer) {
        clearInterval(this.startTimer);
      }
      this.startTimer = setInterval(this.countdown, 125);
    },
  },
  methods: {
    progress() {
      const startSimulatedDate = Date.parse(this.actualMove.start); // Début du move (date simulée) en ms
      const endSimulatedDate = Date.parse(this.actualMove.end); // Fin du move (date simulée) en ms
      const progress = Math.floor(((this.simulatedDate - startSimulatedDate) / (endSimulatedDate - startSimulatedDate)) * 100)
      return progress <= 0 ? 0 : progress > 100 ? 100 : progress;
    },
    countdown() {
      this.now = Date.now();
    },
    initOffset() {
      const start = Date.now();
      axios
          .get(urlApi + "time")
          .then(response => {
            const end = Date.now();
            this.offset = start + ((end - start) / 2) - Date.parse(response.data);
            console.log("server offset", this.offset)
          })
          .catch(error => {
                console.log("init offset error", error)
                this.offset = 0;
                this.status = {
                  moveStatus:"REALTIME"
                };
              }
          )
    },
  },
  computed: {
    simulatedDate() {
      const status = this.status;
      const move = status.currentMove;
      if (!move) {
        return this.now;
      }

      switch (status.moveStatus) {
        case 'REALTIME':
          return this.now;
        case 'FINISHED':
          return Date.parse(move.end);
      }

      const startMove = Date.parse(move.start)
      const endMove = Date.parse(move.end)
      const now = (status.moveStatus === 'PAUSED') ? Date.parse(status.statusDate) : (this.now - this.offset);
      const elapsed = now - Date.parse(status.statusDate);
      const remaining = (status.remaining * 1000) - elapsed;
      if (remaining < 0) {
        return endMove;
      }
      const duration = move.duration * 1000;
      return Math.max(startMove, 1000 * Math.trunc(((startMove + (endMove - startMove) * (1 - (remaining / duration)))) / 1000));
    },
    progressInPercent() {
      return this.progress()
    },
    moveName() {
      const status = this.status;


      switch (status.moveStatus) {
        case 'REALTIME':
          return "Aucun virtual day en cours";
        case 'PAUSED':
          return `${status.currentMove.name} ${(status.currentMove.duration === status.remaining) ? "en attente" : "en pause"}`;
        case 'FINISHED':
          return `${this.actualMove.name} terminé`;
      }
      //case 'STARTED':
      if (this.progress() === 100) {
        return `${this.actualMove.name} terminé`;
      } else {
        return `${this.actualMove.name} en cours`;
      }
    }
  }
}
</script>
<style lang="scss">
.min-height-3em {
  min-height: 3em;
}

.bg-date {
  background: #26348B;
}

</style>
